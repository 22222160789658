
export class ReservationSelector extends HTMLElement {
  constructor () {
    super()
    this.checkboxes = new Set()
    this.submitButton = this.querySelector('button[type="submit"]')
    this.summary = this.querySelector('.selection-summary')
    this.summaryTotal = this.summary.querySelector('.selection-total span')
    this.summaryName = this.summary.querySelector('.selection-description')
    this.init()
  }

  init () {
    // Get all checkboxes
    this.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      checkbox.addEventListener('change', () => this.updateSelection())
    })

    // Make the whole reservation item clickable
    this.querySelectorAll('.reservation-item').forEach(item => {
      item.addEventListener('click', (e) => {
        // if (e.target.type === 'checkbox') return
        const checkbox = item.querySelector('input[type="checkbox"]')
        checkbox.checked = !checkbox.checked
        checkbox.dispatchEvent(new Event('change'))
      })
    })
  }

  formatCurrency (amount) {
    return (amount / 100).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  generateGroupName () {
    const selectedCheckboxes = Array.from(
      this.querySelectorAll('input[type="checkbox"]:checked')
    )

    if (selectedCheckboxes.length === 0) return ''

    const dates = new Set(
      selectedCheckboxes.map(cb => cb.dataset.date)
    )

    if (dates.size === 1) {
      return `Reservations for ${Array.from(dates)[0]} (${selectedCheckboxes.length} items)`
    } else {
      const sortedDates = Array.from(dates).sort()
      return `Reservations for ${sortedDates[0]} - ${sortedDates[sortedDates.length - 1]} (${selectedCheckboxes.length} items)`
    }
  }

  updateSelection () {
    const selectedCheckboxes = Array.from(
      this.querySelectorAll('input[type="checkbox"]:checked')
    )

    // Update submit button state
    this.submitButton.disabled = selectedCheckboxes.length === 0

    // Update total
    const total = selectedCheckboxes.reduce((sum, checkbox) =>
      sum + parseInt(checkbox.dataset.amount, 10), 0
    )

    // Update summary visibility and content
    if (selectedCheckboxes.length > 0) {
      this.summary.classList.add('active')
      this.summaryTotal.textContent = this.formatCurrency(total)
      this.summaryName.textContent = this.generateGroupName()
    } else {
      this.summary.classList.remove('active')
    }

    // Update selected state on items
    this.querySelectorAll('.reservation-item').forEach(item => {
      const checkbox = item.querySelector('input[type="checkbox"]')
      item.classList.toggle('selected', checkbox.checked)
    })
  }
}
